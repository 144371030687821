import { Container, Grid, Typography, Box, Tooltip } from "@mui/material";
import React from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import classes from "../Styles/about.module.css";
import CodeIcon from "@mui/icons-material/Code";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import PersonalInformation from "../Components/PersonalInformation";
import CloseIcon from "@mui/icons-material/Close";
import Info from "../Components/Info";
import ProfessionalInfo from "../Components/ProfessionalInfo";
import Hobbies from "../Components/Hobbies";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import Jobberman from "../Assets/jobberman-certificate.png";
import Kodecamp from "../Assets/kodecamp-certificate.png";
import SideHustle from "../Assets/side-hustle-certificate.png";

const AboutMe = () => {
  return (
    <>
      <Container disableGutters maxWidth="xl" className={classes.about_body}>
        <Grid container className={classes.grid_container}>
          <Grid
            item
            xs={12}
            sx={{
              display: { xs: "block", md: "none" },
              pl: { xs: 3, md: 0 },
              pb: { xs: 3, md: 0 },
              pt: 3,
              textAlign: "center",
            }}
          >
            <Typography className={classes.about_text}>_about-me</Typography>
          </Grid>
          <Grid
            item
            className={classes.grid_item}
            sx={{ display: { xs: "none", md: "block" } }}
            md={0.6}
          >
            <NavLink
              style={({ isActive }) =>
                isActive ? { color: "#81A1C1" } : { color: "#607B96" }
              }
              to={`/aboutme/professional-information`}
            >
              <Tooltip title="proffesional-info" placement="right-start">
                <Typography sx={{ pb: 2 }}>
                  <CodeIcon />
                </Typography>
              </Tooltip>
            </NavLink>
            <NavLink
              style={({ isActive }) =>
                isActive ? { color: "#81A1C1" } : { color: "#607B96" }
              }
              to={`/aboutme/personal-information`}
            >
              <Tooltip title="personal-info" placement="right-start">
                <Typography sx={{ pb: 2 }}>
                  <LightbulbIcon />
                </Typography>
              </Tooltip>
            </NavLink>
            <NavLink
              style={({ isActive }) =>
                isActive ? { color: "#81A1C1" } : { color: "#607B96" }
              }
              to={`/aboutme/hobbies`}
            >
              <Tooltip title="hobbies" placement="right-start">
                <Typography sx={{ pb: 2 }}>
                  <VideogameAssetIcon />
                </Typography>
              </Tooltip>
            </NavLink>
          </Grid>

          <Routes>
            <Route exact path="/" element={<PersonalInformation />} />
            <Route
              exact
              path="personal-information"
              element={<PersonalInformation />}
            />
            <Route
              exact
              path="professional-information"
              element={<ProfessionalInfo />}
            />
            <Route exact path="hobbies" element={<Hobbies />} />
          </Routes>
          <Grid
            item
            md={5}
            sx={{ borderRight: { xs: "none", md: "0.25px solid #1E2D3D" } }}
          >
            <Box
              sx={{
                overflowY: { xs: "visible", md: "scroll" },
                height: "100%",
              }}
              className={classes.about_overflow}
            >
              <Box
                sx={{
                  borderBottom: { xs: "none", md: "0.25px solid #1E2D3D" },
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{
                      color: "#607B96",
                      borderRight: "0.25px solid #1E2D3D",
                      fontSize: "16px",
                      display: { xs: "none", md: "block" },
                      fontFamily: "'Fira Code', monospace !important",
                      pt: 1.4,
                      pb: 1,
                      pl: 2,
                    }}
                  >
                    personal-info
                    <CloseIcon
                      sx={{ fontSize: "18px", pt: 0.4, pl: 4, pr: 2 }}
                    />
                  </Typography>
                  <Typography
                    sx={{
                      color: "#FFFFFF",

                      fontSize: "16px",
                      display: { xs: "block", md: "none" },
                      fontFamily: "'Fira Code', monospace !important",
                      pt: 5,
                      pb: 1,
                      pl: 2,
                    }}
                  >
                    {`// personal-info`}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: { xs: "none", md: "block" }, p: 2 }}>
                <Info text={" /**"} number={1} />
                <Info text={"  * About Me"} number={2} />
                <Info text={" * I am a coding enthusiast."} number={3} />
                <Info
                  text={" * The Tech world has really changed my"}
                  number={4}
                />
                <Info
                  text={" * perspective about different things,"}
                  number={5}
                />
                <Info
                  text={" * It has helped me identify things I did"}
                  number={6}
                />
                <Info text={" * not even know I had"} number={7} />

                <Info
                  text={
                    " * I love coding, making music, playing games and reading"
                  }
                  number={8}
                />
                <Info
                  text={" * I am a friendly person. I love working with teams "}
                  number={9}
                />

                <Info
                  text={" * of the same vision  or goals as mine. I am always"}
                  number={10}
                />
                <Info
                  text={
                    " * eager to learn new things. I find the most passion in"
                  }
                  number={11}
                />
                <Info
                  text={
                    " * web designing and I have over four years working experience."
                  }
                  number={12}
                />
                <Info text={" * I am fully proficient in"} number={13} />
                <Info
                  text={
                    " * HTML, CSS, TAILWIND CSS, BOOTSTRAP CSS, STYLED COMPONENT, MUI, JAVASCRIPT, TYPESCRIPT,"
                  }
                  number={14}
                />
                <Info
                  text={
                    " * REACT JS, NEXT JS, FIGMA, FIREBASE, GIT VERSION CONTROL"
                  }
                  number={15}
                />
                <Info text={" **/"} number={16} />
              </Box>
              <Box sx={{ display: { xs: "block", md: "none" }, pt: 4, pb: 5 }}>
                <Typography className={classes.about_info_small}>
                  I am a coding enthusiast. The Tech world has really changed my
                  perspective about different things, perspective about
                  different things. It has helped me identify things I did not
                  even know I had. I love coding, making music, playing games
                  and reading. I am a friendly person. I love working with teams
                  of the same vision or goals as mine. I am always eager to
                  learn new things. I find the most passion in web designing.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Typography
              sx={{
                color: "#FFFFFF",

                fontSize: "16px",

                fontFamily: "'Fira Code', monospace !important",
                pt: 2,
                pb: 1,
                pl: 2,
              }}
            >
              {`// Certifications`}
            </Typography>
            <Box
              sx={{
                width: "100%",
                mt: 10,
              }}
            >
              <Carousel
                autoPlay={true}
                centerSlidePercentage={20}
                infiniteLoop={true}
                interval={3000}
                stopOnHover={true}
                swipeable={true}
                width="100%"
                transitionTime={2000}
                showIndicators={false}
                showThumbs={false}
              >
                <div>
                  <img
                    style={{ width: "100%", height: "300px" }}
                    src={Kodecamp}
                    alt="Babatunde-kodecamp-certificate"
                  />
                </div>
                <div>
                  <img
                    style={{ width: "100%", height: "300px" }}
                    src={SideHustle}
                    alt="Babatunde-sidehustle-certificate"
                  />
                </div>
                <div>
                  <img
                    style={{ width: "100%", height: "300px" }}
                    src={Jobberman}
                    alt="Babatunde-jobberman-certificate"
                  />
                </div>
              </Carousel>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AboutMe;
