import React, { useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import classes from "../Styles/about.module.css";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FolderIcon from "@mui/icons-material/Folder";
import BallotIcon from "@mui/icons-material/Ballot";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  skills,
  experience,
  hobbies,
  contacts,
  education,
} from "../Utils/Data";

const ProfessionalInfo = () => {
  const [isInterestOpen, setIsInterest] = useState(true);
  const [isPersonalOpen, setIsPersonal] = useState(true);
  const [isProfOpen, setIsProf] = useState(false);
  const [isEdOpen, setIsEd] = useState(false);
  const [isContactOpen, setIsContact] = useState(true);
  const [isSkillOpen, setIsSkill] = useState(false);
  const [isExp, setIsExp] = useState(false);
  const [isRefer, setIsRefer] = useState(false);
  const [isHobbies, setIsHob] = useState(false);

  return (
    <>
      <Grid item md={2.4} xs={12} className={classes.grid_item_inner}>
        <Box
          onClick={() => setIsPersonal(!isPersonalOpen)}
          sx={{
            display: { xs: "flex", md: "none" },
            color: "white",
            width: "100%",
            borderBottom: "0.25px solid #1E2D3D",
            backgroundColor: { xs: "#1E2D3D", md: "transparent" },
            pt: 2,
            pb: 1,
            cursor: "pointer",
          }}
        >
          {isPersonalOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}

          <Typography className={classes.about_text}>
            personal-information
          </Typography>
        </Box>
        <Box sx={{ display: isPersonalOpen ? "block" : "none" }}>
          <Box
            onClick={() => setIsInterest(!isInterestOpen)}
            sx={{
              display: { xs: "flex", md: "none" },
              color: "#607B96",
              width: "100%",
              pl: 0.4,
              pt: 2,
              pb: 1,
              cursor: "pointer",
            }}
          >
            {isInterestOpen ? (
              <ArrowForwardIosIcon
                sx={{ fontSize: "15px", pt: 0.3, transform: "rotate(90deg)" }}
              />
            ) : (
              <ArrowForwardIosIcon sx={{ fontSize: "15px", pt: 0.3 }} />
            )}

            <FolderIcon
              sx={{ color: "#43D9AD", pl: 0.5, pr: 1, fontSize: "20px" }}
            />
            <Typography className={classes.about_text_folder}>
              interests
            </Typography>
          </Box>
          <Box sx={{ display: isInterestOpen ? "block" : "none" }}>
            {hobbies.map((item) => (
              <Box
                key={item.id}
                sx={{
                  display: { xs: "flex", md: "none" },
                  color: "#607B96",
                  width: "100%",
                  pl: 0.4,
                  pt: 2,
                  pb: 1,
                }}
              >
                <BallotIcon
                  sx={{ color: "#81A1C1", pl: 2.2, pr: 1, fontSize: "20px" }}
                />
                <Typography className={classes.about_text_folder_under}>
                  {item.name}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box
            onClick={() => setIsEd(!isEdOpen)}
            sx={{
              display: { xs: "flex", md: "none" },
              color: "#607B96",
              width: "100%",
              pl: 0.4,
              pt: 2,
              pb: 1,
              cursor: "pointer",
            }}
          >
            {isEdOpen ? (
              <ArrowForwardIosIcon
                sx={{ fontSize: "15px", pt: 0.3, transform: "rotate(90deg)" }}
              />
            ) : (
              <ArrowForwardIosIcon sx={{ fontSize: "15px", pt: 0.3 }} />
            )}
            <FolderIcon
              sx={{ color: "#3A49A4", pl: 0.5, pr: 1, fontSize: "20px" }}
            />
            <Typography className={classes.about_text_folder}>
              education
            </Typography>
          </Box>
          <Box sx={{ display: isEdOpen ? "block" : "none" }}>
            {education.map((item) => (
              <Box
                key={item.id}
                sx={{
                  display: "flex",
                  color: "#607B96",
                  width: "100%",
                  pl: 0.4,
                  pt: 2,
                  pb: 1,
                }}
              >
                <BallotIcon
                  sx={{ color: "#81A1C1", pl: 2.2, pr: 1, fontSize: "20px" }}
                />
                <Typography className={classes.about_text_folder_under}>
                  {item.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        {/* prof */}
        <Box
          onClick={() => setIsProf(!isProfOpen)}
          sx={{
            display: "flex",
            color: "white",
            width: "100%",
            borderBottom: "0.25px solid #1E2D3D",
            backgroundColor: { md: "transparent", xs: "#1E2D3D" },
            mt: { xs: 0.5, md: 0 },
            mb: { xs: 0.5, md: 0 },
            pt: 2,
            pb: 1,
            cursor: "pointer",
          }}
        >
          {isProfOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}

          <Typography className={classes.about_text}>
            professional-information
          </Typography>
        </Box>

        <Box sx={{ display: isProfOpen ? "block" : "none" }}>
          <Box
            onClick={() => setIsSkill(!isSkillOpen)}
            sx={{
              display: "flex",
              color: "#607B96",
              width: "100%",
              pl: 0.4,
              pt: 2,
              pb: 1,
              cursor: "pointer",
            }}
          >
            {isSkillOpen ? (
              <ArrowForwardIosIcon
                sx={{ fontSize: "15px", pt: 0.3, transform: "rotate(90deg)" }}
              />
            ) : (
              <ArrowForwardIosIcon sx={{ fontSize: "15px", pt: 0.3 }} />
            )}

            <FolderIcon
              sx={{ color: "#43D9AD", pl: 0.5, pr: 1, fontSize: "20px" }}
            />
            <Typography className={classes.about_text_folder}>
              skills
            </Typography>
          </Box>
          <Box sx={{ display: isSkillOpen ? "block" : "none" }}>
            {skills.map((item) => (
              <Box
                key={item.id}
                sx={{
                  display: "flex",
                  color: "#607B96",
                  width: "100%",
                  pl: 0.4,
                  pt: 2,
                  pb: 1,
                }}
              >
                <BallotIcon
                  sx={{ color: "#81A1C1", pl: 2.2, pr: 1, fontSize: "20px" }}
                />
                <Typography className={classes.about_text_folder_under}>
                  {item.name}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box
            onClick={() => setIsExp(!isExp)}
            sx={{
              display: "flex",
              color: "#607B96",
              width: "100%",
              pl: 0.4,
              pt: 2,
              pb: 1,
              cursor: "pointer",
            }}
          >
            {isExp ? (
              <ArrowForwardIosIcon
                sx={{ fontSize: "15px", pt: 0.3, transform: "rotate(90deg)" }}
              />
            ) : (
              <ArrowForwardIosIcon sx={{ fontSize: "15px", pt: 0.3 }} />
            )}
            <FolderIcon
              sx={{ color: "#3A49A4", pl: 0.5, pr: 1, fontSize: "20px" }}
            />
            <Typography className={classes.about_text_folder}>
              experience
            </Typography>
          </Box>
          <Box sx={{ display: isExp ? "block" : "none" }}>
            {experience.map((item) => (
              <Box
                key={item.id}
                sx={{
                  display: "flex",
                  color: "#607B96",
                  width: "100%",
                  pl: 0.4,
                  pt: 2,
                  pb: 1,
                }}
              >
                <BallotIcon
                  sx={{ color: "#81A1C1", pl: 2.2, pr: 1, fontSize: "20px" }}
                />
                <Typography className={classes.about_text_folder_under}>
                  {item.name} ({item.duration})
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        {/*end prof */}
        {/* hobbies for mobile */}
        <Box
          onClick={() => setIsHob(!isHobbies)}
          sx={{
            display: { xs: "flex", md: "none" },
            color: "white",
            width: "100%",
            borderBottom: "0.25px solid #1E2D3D",
            backgroundColor: "#1E2D3D",
            mt: 0.5,
            mb: 0.5,
            pt: 2,
            pb: 1,
            cursor: "pointer",
          }}
        >
          {isHobbies ? <ArrowDropDownIcon /> : <ArrowRightIcon />}

          <Typography className={classes.about_text}>hobbies</Typography>
        </Box>
        <Box sx={{ display: isHobbies ? "block" : "none" }}>
          {hobbies.map((item) => (
            <Box
              key={item.id}
              sx={{
                display: "flex",
                color: "#607B96",
                width: "100%",
                pl: 0.4,
                pt: 2,
                pb: 1,
              }}
            >
              <BallotIcon
                sx={{ color: "#81A1C1", pl: 2.2, pr: 1, fontSize: "20px" }}
              />
              <Typography className={classes.about_text_folder_under}>
                {item.name}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box
          onClick={() => setIsContact(!isContactOpen)}
          sx={{
            display: "flex",
            color: "white",
            width: "100%",
            borderTop: "0.25px solid #1E2D3D",
            borderBottom: "0.25px solid #1E2D3D",
            backgroundColor: { xs: "#1E2D3D", md: "transparent" },
            pt: 2,
            pb: 1,
            cursor: "pointer",
          }}
        >
          {isContactOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}

          <Typography className={classes.about_text}>contacts</Typography>
        </Box>
        <Box sx={{ display: isContactOpen ? "block" : "none" }}>
          {contacts.map((item) => (
            <Box
              sx={{
                display: "flex",
                color: "white",
                width: "100%",

                pt: 2,
                pb: 1,
              }}
            >
              {item.icon}
              <Typography className={classes.about_text_folder_under}>
                {item.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    </>
  );
};

export default ProfessionalInfo;
