import { FaReact, FaHtml5 } from "react-icons/fa";

// images
import KodeCamp from "../Assets/kodecamp.png";
import LuxuryFeet from "../Assets/luxury-feet.png";
import EchoThrive from "../Assets/echo-thrive.png";
import RedStore from "../Assets/red-store.png";
import ArteriDashboard from "../Assets/arteri-dashboard.png";
import AchieverAcademy from "../Assets/achiever-academy.png";
import CountryGen from "../Assets/country-gen.png";
import TastyRestaurant from "../Assets/tasty-restaurant.png";
import Foodie from "../Assets/foodie.png";
import Trava from "../Assets/trava.png";
import TravaApp from "../Assets/trava-app.png";
import TasteClan from "../Assets/tasteclan.png";
const projects = [
  {
    id: "1",
    name: "Kodecamp",
    description: "A tech educational website",
    icon: <FaReact style={{ fontSize: "100%" }} />,
    link: "https://kode.camp/",
    pic: KodeCamp,
    lang: "React",
  },
  {
    id: "2",
    name: "TasteClan",
    description: "A food ordering website",
    icon: <FaReact style={{ fontSize: "100%" }} />,
    link: "https://tasteclan.com/",
    pic: TasteClan,
    lang: "React",
  },
  {
    id: "3",
    name: "EchoThrive",
    description: "A music streaming web app",
    icon: <FaReact style={{ fontSize: "100%" }} />,
    link: "https://echo-thrive.netlify.app",
    pic: EchoThrive,
    lang: "React",
  },
  {
    id: "4",
    name: "Trava App",
    description: "A parcel delivery logistics app",
    icon: <FaReact style={{ fontSize: "100%" }} />,
    link: "https://travaapp.com/",
    pic: TravaApp,
    lang: "React",
  },
  {
    id: "5",
    name: "Tasty Cafe",
    description: "A restaurant website",
    icon: <FaReact style={{ fontSize: "100%" }} />,
    link: "https://tasty-cafe.netlify.app/",
    pic: TastyRestaurant,
    lang: "React",
  },

  {
    id: "6",
    name: "RedStore",
    description: "A men's clothing e-commerce website",
    icon: <FaHtml5 style={{ fontSize: "100%" }} />,
    link: "https://tunexecommerce.netlify.app/",
    pic: RedStore,
    lang: "HTML",
  },
  {
    id: "7",
    name: "Arteri Dashboard",
    description: "A fintech organization dashboard",
    icon: <FaReact style={{ fontSize: "100%" }} />,
    link: "https://arteri-dashboard.netlify.app/",
    pic: ArteriDashboard,
    lang: "React",
  },

  {
    id: "8",
    name: "Luxury Feet",
    description: "An online shoe shopping brand",
    icon: <FaHtml5 style={{ fontSize: "100%" }} />,
    link: "https://luxury-feet.netlify.app",
    pic: LuxuryFeet,
    lang: "HTML",
  },
  {
    id: "9",
    name: "Achiever's Academy",
    description: "A student dashboard for tertiary institutions",
    icon: <FaReact style={{ fontSize: "100%" }} />,
    link: "https://achievers-academy.netlify.app/",
    pic: AchieverAcademy,
    lang: "React",
  },
  {
    id: "10",
    name: "Where In The World",
    description: "Where in the world are you interested in going to?",
    icon: <FaHtml5 style={{ fontSize: "100%" }} />,
    link: "https://countrygen.netlify.app/",
    pic: CountryGen,
    lang: "HTML",
  },
  {
    id: "11",
    name: "Foodie",
    description: "A food catalogue",
    icon: <FaHtml5 style={{ fontSize: "100%" }} />,
    link: "https://foodie110.netlify.app/",
    pic: Foodie,
    lang: "HTML",
  },
  {
    id: "12",
    name: "Trava Waitlist",
    description: "A parcel delivery logistics app",
    icon: <FaHtml5 style={{ fontSize: "100%" }} />,
    link: "https://trava-waitlist.netlify.app/",
    pic: Trava,
    lang: "HTML",
  },
];

export default projects;
