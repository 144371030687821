import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import classes from "../Styles/footer.module.css";
import { GitHub, Instagram, LinkedIn, Twitter } from "@mui/icons-material/";
import { mySocialHandle } from "../Utils/Data";

const Footer = () => {
  return (
    <Container disableGutters maxWidth="xl">
      <Grid container className={classes.nav_container}>
        <Grid item xs={4} md={2} className={classes.nav_item}>
          <Box className={classes.nav_links}>
            <Typography className={classes.nav_link_name}>
              find me on:
            </Typography>
          </Box>
        </Grid>

        <Grid itemm xs={2} md={0.5}>
          <a
            target="blank"
            style={{ textDecoration: "none" }}
            href={mySocialHandle.linkedIn_link}
          >
            <Box className={classes.nav_links}>
              <LinkedIn sx={{ pt: 1.5 }} />
            </Box>
          </a>
        </Grid>
        <Grid item xs={2} md={0.5}>
          <a
            target="blank"
            style={{ textDecoration: "none" }}
            href={mySocialHandle.instagram_link}
          >
            <Box className={classes.nav_links}>
              <Instagram sx={{ pt: 1.5 }} />
            </Box>
          </a>
        </Grid>
        <Grid item xs={2} md={0.5}>
          <a
            target="blank"
            style={{ textDecoration: "none" }}
            href={mySocialHandle.twitter_link}
          >
            <Box className={classes.nav_links}>
              <Twitter sx={{ pt: 1.5 }} />
            </Box>
          </a>
        </Grid>
        <Grid sx={{ display: { xs: "none", md: "block" } }} item md={5.5}>
          <Box className={classes.nav_links}></Box>
        </Grid>
        <Grid item md={3} xs={2} className={classes.nav_item}>
          <a
            target="blank"
            style={{ textDecoration: "none" }}
            href={mySocialHandle.github_link}
          >
            {" "}
            <Box
              sx={{ display: "flex", justifyContent: "center" }}
              className={classes.nav_links}
            >
              <Typography
                sx={{ display: { xs: "none", md: "block" }, paddingRight: 1 }}
                className={classes.nav_link_name}
              >
                @Babatunde-Ben
              </Typography>
              <Box sx={{ paddingTop: { xs: "10%", md: "4%" } }}>
                <GitHub />
              </Box>
            </Box>
          </a>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
