import { Container, Grid, Typography, Box } from "@mui/material";
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import classes from "../Styles/contact.module.css";
import CloseIcon from "@mui/icons-material/Close";
import Contact from "../Components/Contact";
const ContactMe = () => {
  const [formValues, setFormValues] = useState({
    user_name: "",
    user_email: "",
    message: "",
  });
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isRequestfailed, setIsRequestfailed] = useState(false);
  const form = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(form);
    console.log(form.current);
    setIsButtonLoading(true);

    emailjs
      .sendForm(
        "service_la0jujq",
        "template_0jglets",
        form.current,
        "c9tdk7pCBGKRhSiGK"
      )
      .then(
        (result) => {
          setIsButtonLoading(false);
          setFormValues({
            user_name: "",
            user_email: "",
            message: "",
          });
          setSubmitted(true);
          setIsRequestfailed(false);
        },
        (error) => {
          setIsButtonLoading(false);
          setIsRequestfailed(true);
        }
      );
  };
  // const handleEmail = (e) => {
  //   setEmail(e.target.value);
  // };
  // const handleMessage = (e) => {
  //   setMessage(e.target.value);
  // };

  const date = new Date();
  return (
    <>
      <Container disableGutters maxWidth="xl" className={classes.about_body}>
        <Grid container className={classes.grid_container}>
          <Grid
            item
            xs={12}
            sx={{
              display: { xs: "block", md: "none" },
              pl: { xs: 3, md: 0 },
              pb: { xs: 3, md: 0 },
              pt: 3,
              textAlign: "center",
            }}
          >
            <Typography className={classes.about_text}>contact_me</Typography>
          </Grid>
          <Contact />

          <Grid
            item
            md={5}
            xs={12}
            sx={{
              borderRight: { xs: "none", md: "0.25px solid #1E2D3D" },
            }}
          >
            <Box
              sx={{
                overflowY: { xs: "visible", md: "scroll" },
                height: "100%",
              }}
              className={classes.about_overflow}
            >
              <Box
                sx={{
                  borderBottom: { xs: "none", md: "0.25px solid #1E2D3D" },
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{
                      color: "#607B96",
                      borderRight: "0.25px solid #1E2D3D",
                      fontSize: "16px",
                      display: { xs: "none", md: "block" },
                      fontFamily: "'Fira Code', monospace !important",
                      pt: 1.4,
                      pb: 1,
                      pl: 2,
                    }}
                  >
                    contacts
                    <CloseIcon
                      sx={{ fontSize: "18px", pt: 0.4, pl: 4, pr: 2 }}
                    />
                  </Typography>
                  <Typography
                    sx={{
                      color: "#FFFFFF",

                      fontSize: "16px",
                      display: { xs: "block", md: "none" },
                      fontFamily: "'Fira Code', monospace !important",
                      pt: 5,
                      pb: 1,
                      pl: 2,
                    }}
                  >
                    {`// contacts`}
                  </Typography>
                </Box>
              </Box>
              {submitted ? (
                <Box
                  className={classes.contatct_container_thanks}
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                >
                  <Typography className={classes.submitted_thanks}>
                    Thank you! 🤘
                  </Typography>
                  <Typography className={classes.form_text}>
                    Your message has been received. You will get a response
                    soon!
                  </Typography>
                  <button
                    type="button"
                    onClick={() => setSubmitted(false)}
                    className={classes.contact_button}
                  >
                    send-new-message
                  </button>
                </Box>
              ) : (
                <form ref={form} onSubmit={handleSubmit}>
                  <Box className={classes.contatct_container}>
                    <Box sx={{ mb: 2 }}>
                      <Typography className={classes.form_text}>
                        name:
                      </Typography>
                      <input
                        type="text"
                        name="user_name"
                        fullWidth
                        value={formValues.name}
                        onChange={handleChange}
                        className={classes.contact_small}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography className={classes.form_text}>
                        email:
                      </Typography>
                      <input
                        type="email"
                        name="user_email"
                        value={formValues.email}
                        onChange={handleChange}
                        className={classes.contact_small}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography className={classes.form_text}>
                        message:
                      </Typography>
                      <textarea
                        name="message"
                        value={formValues.message}
                        onChange={handleChange}
                        className={classes.contact_text}
                      />
                    </Box>
                    {isRequestfailed && (
                      <Typography className={classes.error_message}>
                        Submission failed, please try again.
                      </Typography>
                    )}
                    {/* conditional submit options */}
                    {/* {email === "" || name === "" || message === "" ? (
                    <Typography className={classes.contact_button_text}>
                      Please fill form to continue
                    </Typography>
                  ) : (
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className={classes.contact_button}
                    >
                      <a
                        href="mailto:babatundeben110@gmail.com"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        submit-message
                      </a>
                    </button>
                  )} */}

                    <button
                      disabled={
                        !(
                          formValues.user_name &&
                          formValues.user_email &&
                          formValues.message
                        )
                      }
                      type="submit"
                      className={classes.contact_button}
                    >
                      {isButtonLoading ? (
                        <svg
                          className={classes.button_loader}
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 1.66671C8.35186 1.66671 6.7407 2.15545 5.37029 3.07113C3.99988 3.9868 2.93177 5.28829 2.30104 6.81101C1.67031 8.33373 1.50529 10.0093 1.82683 11.6258C2.14837 13.2423 2.94204 14.7272 4.10748 15.8926C5.27292 17.058 6.75778 17.8517 8.37429 18.1733C9.99079 18.4948 11.6664 18.3298 13.1891 17.699C14.7118 17.0683 16.0133 16.0002 16.929 14.6298C17.8446 13.2594 18.3334 11.6482 18.3334 10"
                            stroke="#F9F9F9"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ) : (
                        "submit-message"
                      )}
                    </button>
                  </Box>
                </form>
              )}
            </Box>
          </Grid>

          {/* input live display */}

          <Grid item md={4.6} sx={{ display: { xs: "none", md: "block" } }}>
            <Typography
              sx={{
                color: "#FFFFFF",

                fontSize: "16px",

                fontFamily: "'Fira Code', monospace !important",
                pt: 2,
                pb: 1,
                pl: 2,
              }}
            >
              <Box sx={{ display: { xs: "none", md: "block" }, p: 2 }}>
                <Typography sx={{ display: "flex", wordBreak: "break-word" }}>
                  <span
                    className={classes.about_numbers}
                    style={{ paddingRight: "25px" }}
                  >
                    1
                  </span>
                  <Box
                    sx={{
                      fontFamily: "'Fira Code', monospace !important",
                      fontSize: "14px !important",
                    }}
                  >
                    <span className={classes.contact_keyword}>const </span>
                    <span className={classes.contact_variable}>button </span>
                    <span>= </span>
                    <span className={classes.contact_object}>document</span>
                    <span className={classes.contact_method}>
                      .querySelector
                    </span>
                    <spam className={classes.contact_invoke}>(</spam>
                    <span className={classes.contact_string}>"#sendBtn"</span>
                    <spam className={classes.contact_invoke}>)</spam>
                  </Box>
                </Typography>
                <Typography>
                  <span
                    className={classes.about_numbers}
                    style={{ paddingRight: "25px" }}
                  >
                    2
                  </span>
                </Typography>
                <Typography sx={{ display: "flex" }}>
                  <span
                    className={classes.about_numbers}
                    style={{ paddingRight: "25px" }}
                  >
                    3
                  </span>
                  <Box
                    sx={{
                      fontFamily: "'Fira Code', monospace !important",
                      fontSize: "14px !important",
                    }}
                  >
                    <span className={classes.contact_keyword}>const </span>
                    <span className={classes.contact_variable}>
                      message
                    </span> ={" "}
                    <span className={classes.contact_invoke}>{`{`}</span>
                  </Box>
                </Typography>
                <Typography sx={{ display: "flex", wordBreak: "break-word" }}>
                  <span
                    className={classes.about_numbers}
                    style={{ paddingRight: "25px" }}
                  >
                    4
                  </span>
                  <Box>
                    <span className={classes.contact_object}>name:</span>{" "}
                    <span className={classes.contact_string}>
                      {formValues.user_name}
                    </span>
                    ,
                  </Box>
                </Typography>
                <Typography sx={{ display: "flex", wordBreak: "break-word" }}>
                  <span
                    className={classes.about_numbers}
                    style={{ paddingRight: "25px" }}
                  >
                    5
                  </span>
                  <Box>
                    <span className={classes.contact_object}>email:</span>{" "}
                    <span className={classes.contact_string}>
                      {formValues.user_email}
                    </span>
                    ,
                  </Box>
                </Typography>
                <Typography sx={{ display: "flex", wordBreak: "break-word" }}>
                  <span
                    className={classes.about_numbers}
                    style={{ paddingRight: "25px" }}
                  >
                    6
                  </span>
                  <Box>
                    <span className={classes.contact_object}>message:</span>{" "}
                    <span className={classes.contact_string}>
                      {formValues.message}
                    </span>
                    ,
                  </Box>
                </Typography>
                <Typography sx={{ display: "flex", wordBreak: "break-word" }}>
                  <span
                    className={classes.about_numbers}
                    style={{ paddingRight: "25px" }}
                  >
                    7
                  </span>
                  <Box>
                    <span className={classes.contact_object}>date:</span>{" "}
                    <span
                      className={classes.contact_string}
                    >{`"${date.toLocaleString("default", {
                      weekday: "short",
                    })} ${date.getUTCDate()} ${date.toLocaleString("default", {
                      month: "short",
                    })}"`}</span>
                    ,
                  </Box>
                </Typography>
                <Typography sx={{ display: "flex" }}>
                  <span
                    className={classes.about_numbers}
                    style={{ paddingRight: "25px" }}
                  >
                    8
                  </span>
                  <Box>
                    <span className={classes.contact_invoke}>{`}`}</span>
                  </Box>
                </Typography>
                <Typography>
                  <span
                    className={classes.about_numbers}
                    style={{ paddingRight: "25px" }}
                  >
                    9
                  </span>
                </Typography>
                <Typography sx={{ display: "flex", wordBreak: "break-word" }}>
                  <span
                    className={classes.about_numbers}
                    style={{
                      paddingRight: "10px",
                      transform: "translateX(-10px)",
                      width: "30px",
                    }}
                  >
                    10
                  </span>
                  <Box>
                    <span className={classes.contact_variable}>button</span>.
                    <span className={classes.contact_method}>
                      addEventListener
                    </span>
                    <span className={classes.contact_invoke}>(</span>
                    <span className={classes.contact_string}>"click"</span>,
                    <span className={classes.contact_method}> {"()"} </span>
                    <span className={classes.contact_method}>{` => `}</span>
                    <span className={classes.contact_method}>{`{`}</span>
                  </Box>
                </Typography>
                <Typography sx={{ display: "flex", wordBreak: "break-word" }}>
                  <span
                    className={classes.about_numbers}
                    style={{
                      transform: "translateX(-10px)",
                      paddingRight: "10px",
                      width: "30px",
                    }}
                  >
                    11
                  </span>
                  <Box>
                    <span className={classes.contact_object}>form</span>.
                    <span className={classes.contact_method}>send</span>
                    <span className={classes.contact_invoke}>{"("}</span>
                    <span className={classes.contact_variable}>message</span>
                    <span className={classes.contact_invoke}>{")"}</span>;
                  </Box>
                </Typography>
                <Typography sx={{ display: "flex", wordBreak: "break-word" }}>
                  <span
                    className={classes.about_numbers}
                    style={{
                      paddingRight: "10px",
                      transform: "translateX(-10px)",
                      width: "30px",
                    }}
                  >
                    12
                  </span>
                  <Box>
                    <span className={classes.contact_method}>{`}`}</span>
                    <span className={classes.contact_invoke}>{`}`}</span>
                  </Box>
                </Typography>
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ContactMe;
