import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

export const hobbies = [
  { id: 1, name: "coding" },
  { id: 2, name: "making music" },
  { id: 3, name: "playing games" },
];

export const skills = [
  { id: 1, name: "HTML" },
  { id: 2, name: "CSS" },
  { id: 3, name: "Tailwind CSS" },
  { id: 4, name: "Boostrap CSS" },
  { id: 5, name: "MUI" },
  { id: 6, name: "Styled Component" },
  { id: 7, name: "JavaScript" },
  { id: 8, name: "TypeScript" },
  { id: 9, name: "React JS" },
  { id: 10, name: "Next JS" },
  { id: 11, name: "Figma" },
  { id: 12, name: "Firebase" },
  { id: 13, name: "Git" },
];

export const education = [
  { id: 1, name: "Federal University of Techhnology, Akure" },
  { id: 2, name: "Great King's Academy" },
];

export const experience = [
  { id: 1, name: "Blache ", duration: "January 2023 - Present" },
  { id: 2, name: "Arteri", duration: "June 2022 - March 2023" },
  { id: 3, name: "Prunedge", duration: "August 2022 - November 2022" },
  { id: 4, name: "Kodecamp", duration: "March 2022 - July 2022" },
  { id: 5, name: "SideHustle Internship", duration: "June 2021 - August 2021" },
];

export const contacts = [
  {
    id: 1,
    name: "babatundeben110@gmail.com",
    icon: (
      <EmailIcon sx={{ color: "#81A1C1", pl: 2.2, pr: 1, fontSize: "20px" }} />
    ),
  },
  {
    id: 2,
    name: "+2349072583081",
    icon: (
      <PhoneIcon sx={{ color: "#81A1C1", pl: 2.2, pr: 1, fontSize: "20px" }} />
    ),
  },
];

export const mySocialHandle = {
  github_link: "https://github.com/Babatunde-Ben",
  github_username: "Babatunde-Ben",
  linkedIn_link: "https://linkedin.com/in/babatundeadeniyi",
  linkedIn_username: "Babatunde Adeniyi",
  twitter_link: "https://twitter.com",
  twitter_username: "",
  instagram_link: "https://instagram.com",
  instagram_username: "",
};
