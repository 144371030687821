import { Box } from "@mui/material";
import React from "react";
import classes from "../Styles/dots.module.css";

const Dots = () => {
  return (
    <>
      <Box sx={{ zIndex: "2" }}>
        <div className={classes.dots}></div>
        <div className={classes.dots2}></div>
        <div className={classes.dots3}></div>
        <div className={classes.dots4}></div>
        <div className={classes.dots5}></div>
        <div className={classes.dots6}></div>
        <div className={classes.dots7}></div>
        <div className={classes.dots8}></div>
        <div className={classes.dots9}></div>
        <div className={classes.dots10}></div>
        <div className={classes.dots11}></div>
        <div className={classes.dots12}></div>
        <div className={classes.dots13}></div>
        <div className={classes.dots14}></div>
        <div className={classes.dots15}></div>
        <div className={classes.dots16}></div>
      </Box>
    </>
  );
};

export default Dots;
