import React from "react";
import classes from "../Styles/home.module.css";
import { useTypewriter } from "react-simple-typewriter";
import { Container, Grid, Typography, Box } from "@mui/material";
import ProfilePicture from "../Assets/babatunde-adeniyi.png";

const Home = () => {
  const { text } = useTypewriter({
    words: ["Frontend Web-Developer"],
    loop: 1,
    cursorStyle: "_",
    typeSpeed: 70,
    deleteSpeed: 50,
    delaySpeed: 1000,

    // onLoopDone: () => console.log("done from typewriter hook"),
  });
  return (
    <>
      <Container
        disableGutters
        maxWidth="xl"
        sx={{
          height: { xs: "85vh", md: "85vh" },
          pl: { xs: 3, md: 15 },
          pr: { xs: 3, md: 15 },
          pt: { xs: 10, md: 0 },
          pb: { xs: 18, sm: 30, md: 0 },
        }}
        className={classes.home_body}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.grid_container}
        >
          <Grid item md={6} xs={12} sx={classes.grid_item}>
            <Typography className={classes.intro_text}>
              Hello World, I am
            </Typography>
            <Typography className={classes.intro_name}>
              Babatunde Adeniyi
            </Typography>
            <Box sx={{ pb: 10 }}>
              <Box
                component="span"
                sx={{
                  color: { xs: "#43D9AD", md: "#73c6e7" },
                  fontSize: { xs: "18px", md: "26px" },
                  fontFamily: " 'Fira Code', monospace !important",
                }}
              >
                {`//`}
              </Box>
              <Box
                component="span"
                sx={{
                  color: { xs: "#43D9AD", md: "#73c6e7" },
                  fontSize: { xs: "20px", md: "28px" },
                  pl: 1,
                  fontFamily: " 'Fira Code', monospace !important",
                }}
              >
                {text}
              </Box>

              <a
                className={classes.intro_download}
                href="https://drive.google.com/file/d/1XxkERru88QPeyBOEHv_NIdrpR0q5WNyF/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
                download="file"
              >
                Download CV
              </a>
            </Box>
            <Typography
              sx={{
                display: { xs: "none", md: "block" },
              }}
              className={classes.intro_comment}
            >
              {`// you can also see it on my Github page`}
            </Typography>
            <Typography
              sx={{
                display: { xs: "block", md: "none" },
              }}
              className={classes.intro_comment}
            >
              {`// find my profile on Github:`}
            </Typography>
            <Typography className={classes.intro_github}>
              <span className={classes.intro_const}>const</span>
              <span className={classes.intro_git}>github</span> =
              <span>
                <a
                  className={classes.intro_gitlink}
                  href="https://github.com/Babatunde-Ben"
                  target="blank"
                >
                  "https://github.com/Babatunde-Ben"
                </a>
              </span>
            </Typography>
            <Box
              sx={{
                position: "relative",
                display: { xs: "block", md: "none" },
              }}
            >
              <Box className={classes.blur_small}></Box>
              <Box className={classes.other_blur_small}></Box>
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: { xs: "none", md: "block" },
              p: 5,
              position: "relative",
            }}
          >
            <Box
              className={classes.hero_image}
              sx={{
                backgroundImage: `url(${ProfilePicture})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            />

            {/* <Box>
              <Box className={classes.blur}></Box>
              <Box className={classes.other_blur}></Box>
            </Box> */}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Home;
