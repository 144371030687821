import React from "react";
import { Typography, Box } from "@mui/material";
import classes from "../Styles/about.module.css";

const Info = ({ number, text }) => {
  return (
    <>
      <Typography sx={{ display: "flex", wordBreak: "break-word" }}>
        <span
          className={classes.about_numbers}
          style={{
            paddingRight: number < 10 ? "25px" : "",
            width: number > 9 && "36px",
          }}
        >
          {number}
        </span>
        <Box>
          <span className={classes.about_info}> {text}</span>
        </Box>
      </Typography>
    </>
  );
};

export default Info;
