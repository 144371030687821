import {
  Container,
  Grid,
  Typography,
  Box,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React, { useState } from "react";
import classes from "../Styles/about.module.css";
import CloseIcon from "@mui/icons-material/Close";
import Project from "../Components/Project";
import projects from "../Assets/projects";
import classess from "../Styles/about.module.css";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FaReact, FaHtml5 } from "react-icons/fa";

const Projects = () => {
  const [isPersonalOpen, setIsPersonal] = useState(true);
  const [state, setState] = useState({
    React: false,
    HTML: false,
  });
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.checked,
    });
  };
  const applyFilters = projects.filter((item) => {
    if (state.React) {
      if (state.HTML) {
        return item.lang === "HTML" || item.lang === "React";
      }
      return item.lang === "React";
    }
    if (state.HTML) {
      if (state.React) {
        return item.lang === "HTML" || item.lang === "React";
      }
      return item.lang === "HTML";
    } else {
      return item;
    }
  });

  return (
    <>
      <Container disableGutters maxWidth="xl" className={classes.about_body}>
        <Grid container className={classes.grid_container}>
          {/* Filter checkboxes */}

          {/* <Grid item md={2} xs={12} className={classess.grid_item_inner}>
            <Grid
              item
              xs={12}
              sx={{
                display: { xs: "block", md: "none" },
                pl: { xs: 3, md: 0 },
                pb: { xs: 3, md: 0 },
                pt: 3,
                textAlign: "center",
              }}
            >
              <Typography className={classes.about_text}>_projects</Typography>
            </Grid>
            <Box
              onClick={() => setIsPersonal(!isPersonalOpen)}
              sx={{
                display: "flex",
                color: "white",
                width: "100%",
                borderBottom: "0.25px solid #1E2D3D",
                backgroundColor: { xs: "#1E2D3D", md: "transparent" },
                pt: 2,
                pb: 1,
                cursor: "pointer",
              }}
            >
              {isPersonalOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}

              <Typography className={classess.about_text}>projects</Typography>
            </Box>
            <Box sx={{ display: isPersonalOpen ? "block" : "none" }}>
              <FormGroup>
                <FormControlLabel
                  sx={{
                    color: "white",
                    pl: 2,
                    fontFamily: " 'Fira Code', monospace !important",
                  }}
                  control={
                    <>
                      <Checkbox
                        onChange={(e) => handleChange(e)}
                        value="React"
                        name="React"
                        sx={{ color: "#607B96" }}
                      />
                      <FaReact
                        style={{ color: "#607B96", paddingRight: "3%" }}
                      />
                    </>
                  }
                  label="React"
                />
                <FormControlLabel
                  sx={{
                    color: "white",
                    pl: 2,
                    fontFamily: " 'Fira Code', monospace !important",
                  }}
                  control={
                    <>
                      <Checkbox
                        onChange={handleChange}
                        value="HTML"
                        name="HTML"
                        sx={{ color: "#607B96" }}
                      />
                      <FaHtml5
                        style={{ color: "#607B96", paddingRight: "3%" }}
                      />
                    </>
                  }
                  label="HTML&CSS"
                />
              </FormGroup>
            </Box>

          </Grid> */}

          {/* <Grid
            item
            md={10}
            sx={{ borderRight: { xs: "none", md: "0.25px solid #1E2D3D" } }}
          > */}
          <Box
            sx={{
              height: "100%",
            }}
            className={classes.about_overflow}
          >
            <Box
              sx={{
                borderBottom: { xs: "none", md: "0.25px solid #1E2D3D" },
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    color: "#607B96",
                    borderRight: "0.25px solid #1E2D3D",
                    fontSize: "16px",
                    display: { xs: "none", md: "block" },
                    fontFamily: "'Fira Code', monospace !important",
                    pt: 1.4,
                    pb: 1,
                    pl: 8,
                  }}
                >
                  {/* {!state.React && !state.HTML
                    ? "all"
                    : state.React
                    ? state.HTML
                      ? "React; Html"
                      : "React"
                    : "Html"} */}
                  My Projects
                  <CloseIcon sx={{ fontSize: "18px", pt: 0.4, pl: 4, pr: 2 }} />
                </Typography>
                <Typography
                  sx={{
                    color: "#FFFFFF",

                    fontSize: "16px",
                    display: { xs: "block", md: "none" },

                    fontFamily: "'Fira Code', monospace !important",
                    pt: 3,
                    pb: 1,
                    pl: 2,
                  }}
                >
                  {`// projects`}
                </Typography>
              </Box>
            </Box>

            <Grid container className={classes.project_container}>
              {applyFilters.map((proj, index) => {
                return (
                  <Project
                    key={index}
                    name={proj.name}
                    index={index + 1}
                    description={proj.description}
                    icon={proj.icon}
                    link={proj.link}
                    image={proj.pic}
                    // stateObj={state}
                  />
                );
              })}
            </Grid>
          </Box>
          {/* </Grid> */}
        </Grid>
      </Container>
    </>
  );
};

export default Projects;
